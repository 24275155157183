$('#circle1').click(() => {
  $(document.body).attr('class', 'blue');
  $('.home-menu a.dot').removeClass('active');
  $('#circle1').addClass('active');
  $('.home-menu ul li').removeClass('active');
  $('.home-menu ul').children().eq(0).addClass('active');
})
$('#circle2').click(() => {
  $(document.body).attr('class', 'orange');
  $('.home-menu a.dot').removeClass('active');
  $('#circle2').addClass('active');
  $('.home-menu ul li').removeClass('active');
  $('.home-menu ul').children().eq(1).addClass('active');

})
$('#circle3').click(() => {
  $(document.body).attr('class', 'green');
  $('.home-menu a.dot').removeClass('active');
  $('#circle3').addClass('active');
  $('.home-menu ul li').removeClass('active');
  $('.home-menu ul').children().eq(2).addClass('active');
})
$('#circle4').click(() => {
  $(document.body).attr('class', 'pink');
  $('.home-menu a.dot').removeClass('active');
  $('#circle4').addClass('active');
  $('.home-menu ul li').removeClass('active');
  $('.home-menu ul').children().eq(3).addClass('active');
})

$(document.body).bind('mousewheel', function (e) {
  clearTimeout($.data(this, 'timer'));
  $.data(this, 'timer', setTimeout(function () {
    if (e.originalEvent.wheelDelta / 120 > 0) {
      console.log('scrolling up !');
      var nthchild = parseInt($(".home-menu .dot.active")[0].id.slice(7 - 1));
      if (nthchild - 1 < 1) {
        nthchild = 4
      } else {
        nthchild -= 1;
      }
      nthchild = "#circle" + nthchild;
      $(nthchild).trigger('click');
    } else {
      console.log('scrolling down !');
      var nthchild = parseInt($(".home-menu .dot.active")[0].id.slice(7 - 1));
      if (nthchild + 1 > 4) {
        nthchild = 1
      } else {
        nthchild += 1;
      }
      nthchild = "#circle" + nthchild;
      $(nthchild).trigger('click');
    }
  }, 250));
});